<template>
	<div class="page-content">
		<!-- <div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<el-form-item label="商品名称" prop="name">
					<el-input v-model="searchForm.name" style="width: 220px;" clearable placeholder="请填写"></el-input>
				</el-form-item>
				<el-form-item label="是否开启兑换" prop="is_use">
					<el-select v-model="searchForm.is_use" clearable placeholder="请选择开放状态">
						<el-option label="开启" value="1"></el-option>
						<el-option label="关闭" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-right: auto;">
					<div class="fill-btn" @click="getList">查询</div>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
					<div class="fill-btn" @click="handShowAdd">新增</div>
				</el-form-item>
			</el-form>
		</div> -->
		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe v-loading="loading" cell-class-name="text-center"
				header-cell-class-name="header-row-class-name">
				<el-table-column prop="id" label="ID" width="60"></el-table-column>
				<el-table-column prop="get_user.nickname" label="用户" min-width="120px"></el-table-column>
				<el-table-column prop="get_user.phone" label="联系电话" min-width="120px"></el-table-column>
				<el-table-column prop="goods_name" label="商品标题" min-width="120px"></el-table-column>
				<el-table-column prop="goods_cover" label="图片" width="50">
					<template slot-scope="{row}">
						<el-image style="width: 40px; height: 40px" :src="row.goods_cover" :preview-src-list="[row.goods_cover]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column prop="integral" min-width="160px" label="兑换积分"></el-table-column>
				<el-table-column prop="is_handle" width="110" label="是否开启兑换">
					<template slot-scope="{row}">
						<el-tag v-if="row.is_handle == 2" type="success">已处理</el-tag>
						<el-tag v-else-if="row.is_handle == 1" type="danger">未处理</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="handle_time" width="148" label="处理时间"></el-table-column>
				<el-table-column prop="created_at" width="148" label="兑换时间"></el-table-column>
				<el-table-column width="80" fixed="right">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-button class="cur-p" type="primary" v-if="row.is_handle==1" size="mini" @click="handleConvert(row)">
								处理
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-content">
				<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
					@current-change="searchForm.page=$event" @size-change="handleSizeChange"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		delToast
	} from './../../utlis/decorators'
	export default {
		name: "convertLogList",
		data() {
			return {
				loading: false,
				total: 0,
				searchForm: {
					page: 1,
					limit: 10,
				},
				tableData: [],
			}
		},
		created() {
			this.getList();
		},
		watch: {
			'searchForm.page'() {
				this.getList();
			},
		},
		methods: {
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			//获取列表
			async getList() {
				this.loading = true
				let [e, data] = await this.$api.getConvertLogListApi(this.searchForm);
				this.loading = false
				if (e) return;
				if (data.code === 200) {
					this.tableData = data.data.data;
					this.total = data.data.total;
				}
			},
			//处理兑换记录
			@delToast('此操作将处理兑换记录, 是否继续?')
			async handleConvert(row) {
				let [e, data] = await this.$api.handleConvertLogApi({
					id: row.id,
				});
				if (e) return;
				if (data.code === 200) {
					this.getList();
					this.$message.success('操作成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
		},
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	/deep/ .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}

	.bm-view {
		width: 800px;
		height: 400px;
	}

	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}
</style>
